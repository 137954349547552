'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const EmailInputStepRenderer = createStepRenderer<RendererSteps['EmailInput']>(
  ({ headline, StepLayout, errors, loading, fields, subtext }) => {
    const { label, placeholder, name } = fields[0]
    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
          <FormControl.Label>{label}</FormControl.Label>
          <TextField
            size="large"
            name={name}
            placeholder={placeholder}
            inputAttributes={{
              inputMode: 'email',
            }}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)
