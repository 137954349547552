import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Text, type TextProps } from 'reshaped'
import { markdownComponents } from './components'
import { overflowWrap } from './styles.css'

interface Props extends TextProps {
  content: string
}

export const MarkdownText = ({
  content,
  align,
  maxLines,
  color,
  decoration,
  weight,
  variant,
}: Props) => {
  return (
    <Text
      className={overflowWrap}
      {...{ align, maxLines, color, decoration, weight, variant }}
    >
      <Markdown rehypePlugins={[rehypeRaw as any]} components={markdownComponents}>
        {content}
      </Markdown>
    </Text>
  )
}
