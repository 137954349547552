import { P, match } from 'ts-pattern'

const pluralRules = new Intl.PluralRules('en-US')

/**
 * Returns the correct plural form of the given word, based on the passed quantity.
 * If the word has an atypical plural form (e.g. cherry / cherries), it must also be provided.
 * @example
 *  1 plum => plum
 *  2 plum => plums
 *  2 cherry cherries => cherries
 *
 * @param number Number of items to determine which form to use
 * @param singularForm Singular form of the word, e.g. Cherry
 * @param pluralForm Optional plural form of the word, e.g. Cherries. If left undefined, an 'S' will be added to the singular form.
 */
export const pluralize = (number: number, singularForm: string, pluralForm?: string) =>
  match([pluralRules.select(number), pluralForm])
    .with(['one', P.any], () => singularForm)
    .with([P.not('one'), P.string.select('plural')], ({ plural }) => plural)
    .otherwise(() => `${singularForm}s`)
