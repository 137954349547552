'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import { DateField } from '../fields/DateField'

export const DobInputStepRenderer = createStepRenderer<RendererSteps['DobInput']>(
  ({ headline, StepLayout, errors, loading, fields, subtext }) => {
    const { label, placeholder, name, value } = fields[0]
    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
          <DateField
            size="large"
            label={label}
            name={name}
            placeholder={placeholder}
            defaultValue={value}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)
