'use client'
import { useNumberFormat } from '@react-input/number-format'
import { FormControl, TextField, type TextFieldProps } from 'reshaped'

type Props = {
  /** Defaults to 0 */
  maximumFractionDigits?: number
  label: string
} & Omit<TextFieldProps, 'value'>

/**
 * `NumberField` wraps the Reshaped `TextField` component, applying an input mask in the number format.
 */
export const NumberField = ({
  inputAttributes,
  maximumFractionDigits = 0,
  label,
  ...props
}: Props) => {
  const inputRef = useNumberFormat({ locales: 'en', maximumFractionDigits })
  return (
    <>
      <FormControl.Label>{label}</FormControl.Label>
      <TextField
        {...props}
        inputAttributes={{
          ...inputAttributes,
          inputMode: 'numeric',
          ref: inputRef,
        }}
      />
    </>
  )
}
