import { getCNFAwareURLClient } from '@utilities/unsafe'

interface Option {
  value: string
  label: string
}

// TODO: error Handle this step, maybe force user to do vin if service is down

export const fetchYears = async (pathname: string): Promise<Option[]> => {
  try {
    const response = await fetch(
      getCNFAwareURLClient(pathname, '/search/api/getAll?type=years'),
      {
        method: 'POST',
      },
    )

    if (!response.ok) {
      const errorText = await response.text()
      throw new Error(`API Error: ${response.status} ${errorText}`)
    }

    const data = await response.json()
    return data.map((year: string) => ({ value: year, label: year }))
  } catch (error) {
    console.error('Error fetching years:')
    return []
  }
}

export const fetchMakes = async (pathname: string, year: string): Promise<Option[]> => {
  try {
    const response = await fetch(
      getCNFAwareURLClient(pathname, `/search/api/getAll?type=makes&years=${year}`),
      {
        method: 'POST',
      },
    )

    if (!response.ok) {
      const errorText = await response.text()
      throw new Error(`API Error: ${response.status} ${errorText}`)
    }

    const data = await response.json()
    return data.map((make: string) => ({ value: make, label: make }))
  } catch (error) {
    console.error('Error fetching makes:')
    return []
  }
}

export const fetchModels = async (
  pathname: string,
  year: string,
  make: string,
): Promise<Option[]> => {
  try {
    const response = await fetch(
      getCNFAwareURLClient(
        pathname,
        `/search/api/getAll?type=models&years=${year}&make=${make}`,
      ),
      {
        method: 'POST',
      },
    )

    if (!response.ok) {
      const errorText = await response.text()
      throw new Error(`API Error: ${response.status} ${errorText}`)
    }

    const data = await response.json()
    return data.map((model: string) => ({ value: model, label: model }))
  } catch (error) {
    return []
  }
}

export const fetchTrims = async (
  pathname: string,
  year: string,
  make: string,
  model: string,
): Promise<Option[]> => {
  try {
    const response = await fetch(
      getCNFAwareURLClient(
        pathname,
        `/search/api/getAll?type=trims&years=${year}&make=${make}&model=${model}`,
      ),
      {
        method: 'POST',
      },
    )

    if (!response.ok) {
      throw new Error(`API Error: ${response.status}`)
    }

    const data = await response.json()
    return data.map((trim: { trim: string; uuid: string }) => ({
      value: trim.uuid,
      label: trim.trim,
    }))
  } catch (error) {
    return []
  }
}
