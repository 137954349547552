import type { PropsWithChildren } from 'react'
import type Markdown from 'react-markdown'
import { Text, type TextProps, View } from 'reshaped'
import { Abbreviation } from './Abbreviation'

type Components = NonNullable<Parameters<typeof Markdown>[0]['components']>
const createTextVariant =
  (props: Omit<TextProps, 'as'>) =>
  ({ children }: PropsWithChildren) => <Text {...props}>{children}</Text>

export const markdownComponents: Components = {
  h1: createTextVariant({ variant: 'title-1' }),
  h2: createTextVariant({ variant: 'title-2' }),
  h3: createTextVariant({ variant: 'title-3' }),
  h4: createTextVariant({ variant: 'title-4' }),
  h5: createTextVariant({ variant: 'title-5' }),
  ul: ({ children }) => (
    <View as="ul" paddingStart={4} attributes={{ style: { listStyle: 'disc' } }}>
      {children}
    </View>
  ),
  li: ({ children }) => <Text as="li">{children}</Text>,
  p: ({ children }) => <Text as="p">{children}</Text>,
  abbr: ({ children, title }) => <Abbreviation title={title}>{children}</Abbreviation>,
  strong: ({ children }) => (
    <Text as="strong" color="primary">
      {children}
    </Text>
  ),
}
