'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import { NumberField } from '../fields/NumberField'

export const AutoMonthlyPaymentInput = createStepRenderer<
  RendererSteps['AutoLoanMonthlyPaymentInput']
>(({ headline, StepLayout, errors, loading, fields }) => {
  const { label, placeholder, name } = fields[0]
  return (
    <View gap={6}>
      <StepLayout.Title title={headline} />
      <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
        <NumberField
          prefix="$"
          label={label}
          size="large"
          name={name}
          placeholder={placeholder}
        />
        <FormControl.Error>
          {errors?.find((error) => error.name === name)?.message}
        </FormControl.Error>
      </FormControl>
      <StepLayout.SubmitButton loading={loading} />
    </View>
  )
})
