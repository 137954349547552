'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { FormControl, Select, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import {
  fetchMakes,
  fetchModels,
  fetchTrims,
  fetchYears,
} from './services/autoApiService'

type Option = {
  value: string
  label: string
}

export const AutoRefinanceStepRenderer = createStepRenderer<
  RendererSteps['AutoRefinanceInput']
>(({ headline, StepLayout, errors, loading, fields }) => {
  const pathname = usePathname()
  const [years, setYears] = useState<Option[]>([])
  const [selectedYear, setSelectedYear] = useState<string | null>(null)
  const [makes, setMakes] = useState<Option[]>([])
  const [selectedMake, setSelectedMake] = useState<string | null>(null)
  const [models, setModels] = useState<Option[]>([])
  const [trims, setTrims] = useState<Option[]>([])
  const [selectedTrim, setSelectedTrim] = useState<string>('')

  useEffect(() => {
    fetchYears(pathname).then(setYears)
  }, [pathname])

  const handleYearChange = ({ value }: { value: string }) => {
    setSelectedYear(value)
    setMakes([])
    setModels([])
    setTrims([])
    fetchMakes(pathname, value).then(setMakes)
  }

  const handleMakeChange = ({ value }: { value: string }) => {
    setSelectedMake(value)
    setModels([])
    setTrims([])
    fetchModels(pathname, selectedYear || '', value).then(setModels)
  }

  const handleModelChange = ({ value }: { value: string }) => {
    setTrims([])
    fetchTrims(pathname, selectedYear || '', selectedMake || '', value).then(setTrims)
  }

  const handleTrimChange = ({ value }: { value: string }) => {
    setSelectedTrim(value)
  }

  return (
    <View gap={4}>
      <StepLayout.Title title={headline} />
      <input
        type="hidden"
        name="lead.vehicleInformation.vehicleUuid"
        value={selectedTrim}
      />
      {fields.map(({ label, placeholder, name }) => (
        <FormControl
          size="large"
          key={name}
          hasError={errors?.some((error) => error.name === name)}
        >
          <FormControl.Label>{label}</FormControl.Label>
          <Select
            size="large"
            name={name}
            placeholder={placeholder}
            options={
              label === 'Year'
                ? years
                : label === 'Make'
                  ? makes
                  : label === 'Model'
                    ? models
                    : label === 'Trim'
                      ? trims
                      : []
            }
            onChange={(value) => {
              setSelectedTrim('')
              return label === 'Year'
                ? handleYearChange(value)
                : label === 'Make'
                  ? handleMakeChange(value)
                  : label === 'Model'
                    ? handleModelChange(value)
                    : label === 'Trim'
                      ? handleTrimChange(value)
                      : undefined
            }}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
      ))}

      <StepLayout.SubmitButton loading={loading} />
    </View>
  )
})
