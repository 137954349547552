'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const NameInputStepRenderer = createStepRenderer<RendererSteps['NameInput']>(
  ({ headline, StepLayout, errors, loading, fields, subtext }) => {
    const {
      label: firstNameLabel,
      name: firstNameName,
      placeholder: firstNamePlaceholder,
    } = fields[0]
    const {
      label: lastNameLabel,
      name: lastNameName,
      placeholder: lastNamePlaceholder,
    } = fields[1]
    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl
          size="large"
          hasError={errors?.some((error) => error.name === firstNameName)}
        >
          <FormControl.Label>{firstNameLabel}</FormControl.Label>
          <TextField
            size="large"
            name={firstNameName}
            placeholder={firstNamePlaceholder}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === firstNameName)?.message}
          </FormControl.Error>
        </FormControl>
        <FormControl
          size="large"
          hasError={errors?.some((error) => error.name === lastNameName)}
        >
          <FormControl.Label>{lastNameLabel}</FormControl.Label>
          <TextField size="large" name={lastNameName} placeholder={lastNamePlaceholder} />
          <FormControl.Error>
            {errors?.find((error) => error.name === lastNameName)?.message}
          </FormControl.Error>
        </FormControl>
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)
