'use client'

import { pluralize, useLinkGeneratorWithParameters } from '@utilities/unsafe'

import Link from 'next/link'

export const SeeByLenderLink = ({
  slug,
  offerCount,
}: { slug: string; offerCount: number }) => {
  const href = useLinkGeneratorWithParameters({
    view: 'all',
    'filter[provider]': slug,
  })
  return (
    <Link href={href}>
      See {offerCount} more {pluralize(offerCount, 'offer')}
    </Link>
  )
}
