'use client'
import type { ValidationResult } from '@capabilities/search-steps'
import { createContext, useContext } from 'react'

export const StepContext = createContext<{
  state?: ValidationResult
}>({
  state: undefined,
})

export const useSearchStepContext = () => useContext(StepContext)
