'use client'
import { asEventTrigger } from '@capabilities/actions'
import type { PropsWithChildren } from 'react'
import { Dismissible, Link, Modal, Text, View, useToggle } from 'reshaped'
interface Props {
  logo: JSX.Element
}

// setting to 65ch for desktop so it the comfortably reading block text for desktop
const modalSize = { s: '100vw', m: '75ch' }

export const MoreInfoModal = ({ children, logo }: PropsWithChildren<Props>) => {
  const { active, activate, deactivate } = useToggle(false)
  return (
    <>
      <Link attributes={asEventTrigger('Product Viewed')} onClick={activate}>
        <Text variant="caption-1">More Info</Text>
      </Link>
      <Modal position="center" active={active} onClose={deactivate} size={modalSize}>
        <View gap={3} padding={2}>
          <Dismissible onClose={deactivate} closeAriaLabel="Close modal">
            {logo}
          </Dismissible>
          {children}
        </View>
      </Modal>
    </>
  )
}
