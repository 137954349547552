/**
 * Utility that checks for emptyness (nulls and undefined)
 * @param value
 */
export function isNullish(value?: unknown): value is null | undefined {
  return value === undefined || value === null
}

export function isUndefined(value?: unknown | undefined): value is undefined {
  return value === undefined
}

export function not(direction: boolean) {
  return !direction
}
