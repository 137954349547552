'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { getCNFAwareURLClient } from '@utilities/unsafe'
import { usePathname } from 'next/navigation'
import { type ChangeEvent, useState } from 'react'
import { Autocomplete, FormControl, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const UniversityAttendedStepRenderer = createStepRenderer<
  RendererSteps['UniversityAttendedInput']
>(({ headline, StepLayout, errors, loading, fields, subtext }) => {
  const { label, placeholder, name } = fields[0]
  const [value, setValue] = useState('')
  const pathname = usePathname()
  const [options, setOptions] = useState<string[]>([])

  const getUniversities = async (name: string) => {
    try {
      const res = await fetch(
        getCNFAwareURLClient(pathname, `/search/api/university?name=${name}`),
        { method: 'POST' },
      )
      const data = await res.json()
      if (Array.isArray(data)) {
        setOptions(data)
      }
    } catch (error) {}
  }

  const handleChange = (args: {
    name: string
    value: string
    event?: ChangeEvent<HTMLInputElement> | undefined
  }) => {
    setValue(args.value)
    if (args.value && !options.includes(args.value)) {
      getUniversities(args.value)
    }
  }

  return (
    <View gap={6}>
      <StepLayout.Title title={headline} />
      <StepLayout.Subtext subtext={subtext} />
      <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
        <FormControl.Label>{label}</FormControl.Label>
        <Autocomplete
          name={name}
          size="large"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          inputAttributes={{ autoComplete: 'off' }}
        >
          {options.map((option) => (
            <Autocomplete.Item key={option} value={option}>
              {option}
            </Autocomplete.Item>
          ))}
        </Autocomplete>
        <FormControl.Error>
          {errors?.find((error) => error.name === name)?.message}
        </FormControl.Error>
      </FormControl>
      <StepLayout.SubmitButton loading={loading} />
    </View>
  )
})
