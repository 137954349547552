'use client'
import { Loading } from '@capabilities/search-ui'
import type { PropsWithChildren } from 'react'
import { ContextProvider } from './ContextProvider'
import { useInitializeLeadUuid } from './hooks'

export type StepProviderProps = PropsWithChildren<{
  slug: string
  leadUuid: string
  isInitialized: boolean
}>

export const StepProvider = ({
  children,
  slug,
  leadUuid,
  isInitialized,
}: StepProviderProps) => {
  const isReady = useInitializeLeadUuid(leadUuid, isInitialized)

  return isReady ? (
    <ContextProvider slug={slug} leadUuid={leadUuid}>
      {children}
    </ContextProvider>
  ) : (
    <Loading />
  )
}
