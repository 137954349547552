/**
 *  Like the above function, but for client-side call, where the context is not available
 * @param path
 * @param pathname
 */
export const getCNFAwareURLClient = (
  pathname: string,
  path = '/',
  env = process.env.NODE_ENV,
) => {
  const [network, channel, zone] = pathname.split('/').slice(1)
  if (network === 'network' && channel && zone) {
    return `${env === 'production' ? ['/network', channel, zone].join('/') : ''}${path}`
  }

  // Adding this for dynamic offers case maybe we can remove it later
  if (pathname.startsWith('https') && pathname.includes('network')) {
    return `${pathname}${path}`
  }

  return path
}
