'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import { NumberField } from '../fields/NumberField'

export const AmountInputStepRenderer = createStepRenderer<RendererSteps['AmountInput']>(
  ({ headline, StepLayout, errors, loading, fields, subtext }) => {
    const { label, placeholder, name, value } = fields[0]
    const formattedAmount = value ? new Intl.NumberFormat().format(value) : ''

    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
          <NumberField
            label={label}
            size="large"
            prefix="$"
            name={name}
            defaultValue={formattedAmount}
            placeholder={placeholder}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)
