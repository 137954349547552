import Script from 'next/script'
import { useEffect, useRef } from 'react'
import { TextField, View } from 'reshaped'

//TODO: replace with actual type definitions
declare global {
  interface Window {
    initMap: () => void
  }
  const google: any
}

const initAutocomplete = (
  inputElement: HTMLInputElement,
  onPlaceSelected: (place: any) => void,
) => {
  if (typeof google === 'object' && typeof google.maps === 'object') {
    const autocomplete = new google.maps.places.Autocomplete(inputElement, {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    })

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place) {
        const addressComponents = place.address_components
        let streetNumber = ''
        let route = ''
        addressComponents.forEach((component: { types: any; long_name: string }) => {
          const types = component.types
          if (types.includes('street_number')) {
            streetNumber = component.long_name
          }
          if (types.includes('route')) {
            route = component.long_name
          }
        })

        const formattedAddress = `${streetNumber} ${route}`.trim()
        inputElement.value = formattedAddress
        onPlaceSelected(place)
      }
    })
  }
}

interface AddressInputScriptProps {
  onPlaceSelected: (place: any) => void
}

//TODO: Add debounce, fix the full address flickering onSelect
export const AddressInputScript: React.FC<AddressInputScriptProps> = ({
  onPlaceSelected,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  //TODO: move this to env variable
  const googleKey = 'AIzaSyDYbn5ZDWJ5lXDDPShp4qu5Kg5e9AN3nOY'

  useEffect(() => {
    if (inputRef.current) {
      try {
        window.initMap = () =>
          initAutocomplete(inputRef.current as HTMLInputElement, onPlaceSelected)
      } catch (error) {
        console.error('Failed to initialize autocomplete:', error)
      }
    }
  }, [onPlaceSelected])

  return (
    <View>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`}
        strategy="afterInteractive"
      />
      <TextField
        size="large"
        name="lead.personalInformation.address1"
        placeholder="e.g. 123 Main St"
        inputAttributes={{ ref: inputRef }}
      />
    </View>
  )
}
