'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { useState } from 'react'
import { FormControl, Select, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import { AddressInputScript } from './AddressInputScript'

export const AddressInputStepRenderer = createStepRenderer<RendererSteps['AddressInput']>(
  ({ headline, StepLayout, fields, errors, loading }) => {
    const [address, setAddress] = useState({
      'lead.personalInformation.address1': fields[0].value,
      'lead.personalInformation.address2': fields[1].value,
      'lead.personalInformation.city': fields[2].value,
      'lead.personalInformation.state': fields[3].value,
      'lead.personalInformation.zipcode': fields[4].value,
    })

    const handlePlaceSelected = (place: any) => {
      if (place) {
        const addressComponents = place.address_components
        let streetNumber = ''
        let route = ''
        const newAddress = {
          'lead.personalInformation.address1': '',
          'lead.personalInformation.address2': '',
          'lead.personalInformation.city': '',
          'lead.personalInformation.state': '',
          'lead.personalInformation.zipcode': '',
        }

        addressComponents.forEach(
          (component: { types: any; long_name: string; short_name: string }) => {
            const types = component.types
            if (types.includes('street_number')) {
              streetNumber = component.long_name
            }
            if (types.includes('route')) {
              route = component.long_name
            }
            if (types.includes('locality')) {
              newAddress['lead.personalInformation.city'] = component.long_name
            }
            if (types.includes('administrative_area_level_1')) {
              newAddress['lead.personalInformation.state'] = component.short_name
            }
            if (types.includes('postal_code')) {
              newAddress['lead.personalInformation.zipcode'] = component.long_name
            }
          },
        )

        newAddress['lead.personalInformation.address1'] =
          `${streetNumber} ${route}`.trim()
        setAddress(newAddress)
      }
    }
    const addressError = errors?.find(
      (error) => error.name === 'lead.personalInformation.address1',
    )?.message

    return (
      <View gap={4}>
        <StepLayout.Title title={headline} />
        <FormControl size="large" key="address" hasError={Boolean(addressError)}>
          <FormControl.Label>Address</FormControl.Label>
          <AddressInputScript onPlaceSelected={handlePlaceSelected} />
          <FormControl.Error>{addressError}</FormControl.Error>
        </FormControl>
        {fields
          .filter((field) => field.name !== 'lead.personalInformation.address1')
          .map(({ label, placeholder, name, options }) => (
            <FormControl
              size="large"
              key={name}
              hasError={errors?.some((error) => error.name === name)}
            >
              <FormControl.Label>{label}</FormControl.Label>
              {name === 'lead.personalInformation.state' ? (
                <Select
                  name={name}
                  options={options || []}
                  size="large"
                  placeholder="Select Option"
                  value={address['lead.personalInformation.state']}
                  onChange={(e) => {
                    setAddress((prevAddress) => ({ ...prevAddress, [e.name]: e.value }))
                  }}
                />
              ) : (
                <TextField
                  size="large"
                  name={name}
                  placeholder={placeholder}
                  value={address[name] || ''}
                  onChange={(e) => {
                    setAddress((prevAddress) => ({ ...prevAddress, [e.name]: e.value }))
                  }}
                />
              )}
              <FormControl.Error>
                {errors?.find((error) => error.name === name)?.message}
              </FormControl.Error>
            </FormControl>
          ))}
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)

export const AutocompleteInput = ({
  onPlaceSelected,
}: { onPlaceSelected: (place: any) => void }) => {
  return (
    <View>
      <AddressInputScript onPlaceSelected={onPlaceSelected} />
    </View>
  )
}
