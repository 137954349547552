'use client'
import type { FieldOption } from '@capabilities/search-steps'
import { isNullish } from '@utilities/unsafe'
import { useEffect, useRef, useState } from 'react'
import { FormControl, Link, View } from 'reshaped'
import { CardButton } from './CardButton'

const MAX_BUTTON_WIDTH = 72

type ButtonGroupProps = {
  /** Sets the initial number of items rendered */
  limit?: number
  loading?: boolean
  name: string
  label: string
  options: FieldOption[]
  initialOption?: FieldOption['value']
}

function orderAvailableOptions(
  options: FieldOption[],
  limit?: number,
  initialOption?: FieldOption['value'],
) {
  if (isNullish(initialOption) || isNullish(limit)) return options
  const initialIndex = options.findIndex((option) => option.value === initialOption)
  // Only shift option to the front if initialOption is outside the limit
  if (initialIndex >= limit) {
    return [
      options[initialIndex],
      ...options.filter((_, index) => index !== initialIndex),
    ]
  }

  return options
}

export const ButtonGroupField: React.FC<ButtonGroupProps> = ({
  options,
  name,
  limit,
  label,
  loading,
  initialOption,
}) => {
  const [showAll, setShowAll] = useState(false)
  const orderedOptions = orderAvailableOptions(options, limit, initialOption)
  const numberOfOptionsToDisplay = showAll
    ? orderedOptions.length
    : limit ?? orderedOptions.length
  const shownOptions = orderedOptions.slice(0, numberOfOptionsToDisplay)
  const [selectedOption, setSelectedOption] = useState(initialOption)
  const selectedButtonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    // For better Keyboard UX, return the focus to the selected button after Show More Options is enabled
    if (showAll && selectedButtonRef?.current) {
      selectedButtonRef?.current.focus()
    }
  }, [showAll])

  return (
    <>
      <FormControl.Label>{label}</FormControl.Label>
      <View
        gap={4}
        direction={{ s: 'column', m: shownOptions.length >= 6 ? 'row' : 'column' }}
      >
        {shownOptions.map((option) => {
          return (
            <View key={option.value} width="100%" maxWidth={MAX_BUTTON_WIDTH}>
              <CardButton
                name={name}
                type="submit"
                key={option.value}
                selected={selectedOption === option.value}
                disabled={
                  loading && !isNullish(selectedOption) && selectedOption !== option.value
                }
                loading={selectedOption === option.value && loading}
                value={option.value}
                label={option.label}
                icon={option.icon}
                autoFocus={selectedOption === option.value}
                onFocus={() => setSelectedOption(option.value)}
                onClick={() => setSelectedOption(option.value)}
                ref={option.value === selectedOption ? selectedButtonRef : null}
              />
            </View>
          )
        })}
      </View>
      {numberOfOptionsToDisplay < orderedOptions.length && (
        <View paddingTop={4}>
          <Link variant="plain" onClick={() => setShowAll(true)}>
            Show more options
          </Link>
        </View>
      )}
    </>
  )
}
