'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { useMask } from '@react-input/mask'
import { FormControl, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const PhoneInputStepRenderer = createStepRenderer<RendererSteps['PhoneInput']>(
  ({ headline, StepLayout, errors, loading, fields, subtext }) => {
    const inputRef = useMask({
      mask: '___-___-____',
      separate: true,
      replacement: { _: /\d/ },
    })

    const { label, placeholder, name } = fields[0]

    return (
      <View gap={6}>
        <StepLayout.Title title={headline} />
        <StepLayout.Subtext subtext={subtext} />
        <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
          <FormControl.Label>{label}</FormControl.Label>
          <TextField
            size="large"
            name={name}
            prefix="+1"
            placeholder={placeholder}
            inputAttributes={{
              type: 'tel',
              required: true,
              ref: inputRef,
            }}
          />
          <FormControl.Error>
            {errors?.find((error) => error.name === name)?.message}
          </FormControl.Error>
        </FormControl>
        <StepLayout.SubmitButton loading={loading} />
      </View>
    )
  },
)
