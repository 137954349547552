'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'
import { ButtonGroupField } from '../fields/ButtonGroupField'

export const EmploymentStatusStepRenderer = createStepRenderer<
  RendererSteps['EmploymentStatusInput']
>(({ headline, StepLayout, errors, fields, subtext, loading }) => {
  const { label, name, options, value } = fields[0]
  return (
    <View gap={6}>
      <StepLayout.Title title={headline} />
      <StepLayout.Subtext subtext={subtext} />
      <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
        <ButtonGroupField
          loading={loading}
          label={label}
          name={name}
          options={options}
          initialOption={value}
        />
        <FormControl.Error>
          {errors?.find((error) => error.name === name)?.message}
        </FormControl.Error>
      </FormControl>
    </View>
  )
})
