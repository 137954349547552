'use client'

import type { Base, ValidationError, ValidationResult } from '@capabilities/search-steps'
import { useSearchStepContext } from '@experiences/search'
import { useFormStatus } from 'react-dom'
import { Button, Text, View } from 'reshaped'

type StepLayout = {
  SubmitButton: React.FC<{ disabled?: boolean; loading?: boolean }>
  Title: React.FC<{ title: string }>
  Subtext: React.FC<{ subtext?: string }>
}

type Props<T> = T & {
  StepLayout: StepLayout
  loading: boolean | undefined
  errors: ValidationError['errors'] | undefined
  status: ValidationResult['status'] | undefined
}

const SubmitButton: StepLayout['SubmitButton'] = ({ disabled, loading }) => {
  return (
    <View maxWidth={50}>
      <Button
        type="submit"
        fullWidth
        size="large"
        variant="solid"
        color="positive"
        disabled={disabled}
        loading={loading}
      >
        Continue
      </Button>
    </View>
  )
}

const Title: StepLayout['Title'] = ({ title }) => <Text variant="title-4">{title}</Text>
const Subtext: StepLayout['Subtext'] = ({ subtext }) =>
  subtext ? (
    <View.Item gapBefore={4}>
      <Text color="neutral-faded" variant="body-3">
        {subtext}
      </Text>
    </View.Item>
  ) : null

const StepLayout = { Title, SubmitButton, Subtext }

export const createStepRenderer = <T extends Base>(Component: React.FC<Props<T>>) => {
  return ({ ...props }: T) => {
    const { state } = useSearchStepContext()
    const errors = state?.status === 'error' ? state.errors : undefined
    // Once we upgrade to React 19, we can remove this hook since useActionState will include the pending state for server action requests.
    const { pending } = useFormStatus()

    return (
      <Component
        errors={errors}
        status={state?.status}
        loading={pending}
        StepLayout={StepLayout}
        {...props}
      />
    )
  }
}
