import { Loader, Text, View } from 'reshaped'

export type LoadingProps = {
  title?: string
  subtitle?: string
}

/**
 * Loading component for search UI
 *
 * @returns the loading component for search UI
 */
export const Loading = ({ title, subtitle }: LoadingProps) => (
  <View align="center" justify="center" gap={6}>
    <Loader size="medium" />
    {title && <Text variant="title-4">{title}</Text>}
    {subtitle && (
      <Text color="neutral-faded" variant="body-3">
        {subtitle}
      </Text>
    )}
  </View>
)
