import { forwardRef } from 'react'
import { Card, Icon, Loader, Text, View } from 'reshaped'
import { cardStyle } from './ButtonGroupField.css'

type CardButtonProps = {
  type: HTMLButtonElement['type']
  name: string
  value: string
  label: string
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLButtonElement, Element>) => void
  disabled?: boolean
  loading?: boolean
  selected?: boolean
  icon?: React.JSX.Element
  ref?: React.Ref<HTMLElement>
  autoFocus?: boolean
}

const CARD_HEIGHT = 14

export const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  (
    {
      type,
      name,
      label,
      value,
      selected,
      disabled,
      loading,
      icon,
      autoFocus,
      onClick = () => {},
      onFocus,
    },
    ref,
  ) => {
    return (
      <Card
        as="button"
        className={cardStyle}
        height={CARD_HEIGHT}
        onClick={onClick}
        selected={selected}
        ref={ref}
        attributes={{
          type,
          name,
          value,
          disabled,
          onFocus,
          autoFocus,
        }}
      >
        <View direction="row" justify="space-between">
          <View direction="row" gap={2} align="center">
            {icon && <Icon svg={icon} size="20px" />}
            <Text variant="body-3">{label}</Text>
          </View>
          {loading && <Loader />}
        </View>
      </Card>
    )
  },
)
