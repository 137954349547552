'use client'
import type { ValidationResult } from '@capabilities/search-steps'
import { getCNFAwareURLClient } from '@utilities/unsafe'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

/**
 * Custom hook for redirecting on validation of a given search step.
 *
 * @param result the current step validation result
 */
export const useRedirectOnSuccess = (result: ValidationResult | undefined) => {
  const pathname = usePathname()
  const { push } = useRouter()

  return useEffect(() => {
    if (result?.status === 'success') {
      if (result.nextStep) {
        push(getCNFAwareURLClient(pathname, `/search/${result.nextStep}`))
      } else {
        /**
         * Redirect to search/complete if no next step. Router push is not used
         * here to avoid synchronous navigation. This is likely due to routing
         * service issues.
         */
        window.location.href = getCNFAwareURLClient(pathname, '/search/complete')
      }
    }
  }, [result, push, pathname])
}
