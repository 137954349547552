'use client'
import { useMask } from '@react-input/mask'
import { FormControl, TextField, type TextFieldProps } from 'reshaped'

/**
 * `DateField` wraps the `reshaped` `TextField` component, applying an input mask in the MM/DD/YYYY format.
 */
export const DateField = ({
  inputAttributes,
  label,
  ...props
}: TextFieldProps & { label: string }) => {
  const inputRef = useMask({
    mask: 'MM/DD/YYYY',
    replacement: { M: /\d/, D: /\d/, Y: /\d/ },
  })

  return (
    <>
      <FormControl.Label>{label}</FormControl.Label>
      <TextField
        {...props}
        inputAttributes={{
          ...inputAttributes,
          inputMode: 'numeric',
          ref: inputRef,
        }}
      />
    </>
  )
}
