'use client'
import type { RendererSteps } from '@capabilities/search-steps'
import { FormControl, TextField, View } from 'reshaped'
import { createStepRenderer } from '../createStepRenderer'

export const AutoMilageStepRenderer = createStepRenderer<
  RendererSteps['AutoMilageInput']
>(({ headline, StepLayout, errors, loading, fields }) => {
  const { name, placeholder, label } = fields[0]

  return (
    <View gap={6}>
      <StepLayout.Title title={headline} />
      <FormControl size="large" hasError={errors?.some((error) => error.name === name)}>
        <FormControl.Label>{label}</FormControl.Label>
        <TextField size="large" name={name} placeholder={placeholder} suffix="mi" />
        <FormControl.Error>
          {errors?.find((error) => error.name === name)?.message}
        </FormControl.Error>
      </FormControl>
      <StepLayout.SubmitButton loading={loading} />
    </View>
  )
})
